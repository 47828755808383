import { Link } from '@inertiajs/react';
import { useState } from 'react';
import DateTime from '@/Components/DateTime';
import LetterNavComments from './LetterNavComments';

const Letter = ({ letter, level }) => {

    const [isClicked, setIsClicked] = useState(false);
    const [hearts, setHearts] = useState(letter.up);

    const handleHeart = (e) => {

        e.preventDefault();

        setHearts(hearts+1);
        setIsClicked(true);

        setTimeout(() => {
            setIsClicked(false);
        }, 300);        


        axios.post(route('letter.vote', {id: letter.id, hearts: hearts}))
            .then(response => {
                // do nothing, we've already updated it...
            })
            .catch(error => {
                console.error("There was an error save the vote!", error);
            });        
        
    }

    return (
      <div key="letterContainer-{letter.id}" className="letterContainer py-[25px]">
          <div key="letterNavigation-{letter.id}" className="text-[12px] letterNavigation flex text-pink">
              <div key="letterNavigation-start-{letter.id}" className="flex-1">
                      <Link
                          href={route('letter.details', letter.id)}
                          className="rounded-md py-2 text-pink ring-1 ring-transparent transition hover:underline focus:outline-none focus-visible:ring-[#FF2D20] no-underline"
                      >
                          <DateTime level={level} dateString={letter.post_date}></DateTime>
                      </Link>                    
              </div>
              <div key="letterNavigation-end-{letter.id}" className="justify-end pt-[0px]">
                  <LetterNavComments letterId={letter.id} comment_count={letter.comment_count}/>

                  <span onClick={handleHeart} className="letterHearts cursor-pointer select-none inline-block w-[45px] text-right ml-[0px] pl-[0px] mt-[-20px] pt-[20px] mb-[-20px] pb-[20px] mr-[-20px] pr-[20px] box-content" key="letterNavigation-hearts-{letter.id}">
                      {hearts}

                      <svg xmlns="http://www.w3.org/2000/svg" 
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className={`relative pl-[2px] inline top-[-1px] w-[14px] md:w-[15px] transition-transform duration-300 ease-in-out ${
                            isClicked ? 'scale-125' : 'scale-100'
                          }`}
                          >
                          <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z" />
                      </svg>

                  </span>
              </div>            
          </div>
          <div
              key="letterContent-{letter.id}"
              className="letterContent pt-[30px] text-[16px] break-words"
              dangerouslySetInnerHTML={{ __html: letter.message }}            
              >
          </div>
      </div>    
    ); 
  };

export default Letter;