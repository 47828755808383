const DateTime = ({dateString, level}) => {

    if(level == 1) {
        return dateString;
    }

    const date = new Date(dateString);

    const options = { year: 'numeric', month: 'long', day: 'numeric' }
    const formattedDate = date.toLocaleDateString('en-US', options);

    const timeOptions = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    };

    let formattedTime = date.toLocaleTimeString('en-US', timeOptions);
    formattedTime = formattedTime.replace(' ', '');

    return `${formattedDate} ${formattedTime}`
}

export default DateTime;