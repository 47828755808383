import { router } from "@inertiajs/react";

const LetterNavComments = ({ letterId, comment_count }) => {

    const handleCommentClick = () => {
        router.visit(route('letter.details', {id: letterId}));
    }

    if(comment_count > 0) {

        return (
            <span className="cursor-pointer box-content pl-[12px] ml-[-12px] pt-[20px] mt-[-20px] pb-[20px] mb-[-20px] inline-block" key="letterNavigation-comments-{letterId}" onClick={handleCommentClick}>
                {comment_count}

                <svg xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="inline w-[13px] relative top-[-1px] pl-[2px] md:w-[16px] md:top-[-2px]">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 0 1-.923 1.785A5.969 5.969 0 0 0 6 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337Z" />
                </svg>

            </span>
        )        

    } else {
        return (
            <span></span>
        )
    }

  };

export default LetterNavComments;